import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;
export const GetCasnioHistory = async (data) => {
  const res = await axios({
    url: baseUrl + "/get_casino_bet_history",
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("sfwa_tkn"),
    },
    data,
  });
  
  if (res.data.status) {
    return res.data.data;
  } else {
    return [];
  }
};

export const GetSportHistory = async () => {
  const res = await axios({
    url: baseUrl + "/get_bets",
    method: "get",
    headers: {
      Authorization: localStorage.getItem("sfwa_tkn"),
    },
  });
  
  if (res.data.status) {
    return res.data.data;
  } else {
    return [];
  }
};