import React, { useEffect, useState } from "react";
import AppNavbar from "../components/home/AppNavbar";
import HomeHero from "../components/home/HomeHero";
import NewHomeGameOpction from "../components/newhomepage/NewHomeGameOpction";
import Categories from "../components/home/Categories";
import "../components/newhomepage/newhomepage.css";
import BottomNav from "../components/bottomnav/BottomNav";
import LetsChat from "../components/home/LetsChat";
import Appfooter from "../components/footer/Appfooter";
import LiveSports from "../components/newhomepage/LiveSports";
import CasinoSlots from "../components/casinopage/CasinoSlots";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCasnio } from "../redux/Casino/CasinoSlice";
import { GetCricketEvents } from "../redux/Cricket/CircketSlice";
import CricketEvents from "../components/home/CricketEvents";

import WelcomeBonus from "../components/newhomepage/WelcomeBonus";

import Intercom from '@intercom/messenger-js-sdk';
const NewHomePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllCasnio());
    dispatch(GetCricketEvents());
  }, []);
  const [activeEvent, setActiveEvent] = useState(0);
  const [casinoCategories, setCasinoCategories] = useState([{ name: "all" }]);
  const Casino = useSelector((state) => state.Casino.events);
  useEffect(() => {
    if (casinoCategories.length == 1) {
      if (Casino?.categories?.length > 0) {
        setCasinoCategories((prevCategories) => {
          const newCategories = Casino?.categories?.filter(
            (category) => !prevCategories.includes(category)
          );
          return [...prevCategories, ...newCategories];
        });
      }
    }
  }, [Casino]);
  useEffect(() => {
    window.scroll("top", 0);
  }, []);

  useEffect(() => {
    console.log("Initializing Intercom...");
    
    setInterval(()=>{
      Intercom({
        app_id: 'xm142uyz' // Replace with your actual Intercom app ID
      });
      Intercom('onShow', () => {
        console.log('Intercom messenger is shown');
      });
  
      Intercom('onHide', () => {
        console.log('Intercom messenger is hidden');
      });
  
      Intercom('onUnreadCountChange', (unreadCount) => {
        console.log(`Unread message count: ${unreadCount}`);
      });
  
      Intercom('onBoot', () => {
        console.log('Intercom has booted successfully');
      });
  
      Intercom('onReady', () => {
        console.log('Intercom is ready');
        Intercom('show'); // Make sure Intercom is shown when it's ready
      });
    },[1000])

    return () => {
      console.log("Shutting down Intercom...");
      Intercom('shutdown');
    };
  }, [Intercom]);
  return (
    <div>
      <HomeHero />
      <NewHomeGameOpction />
      <Categories />
      <LiveSports />
      <CasinoSlots
        event={Casino?.games}
        Categorie={casinoCategories}
        active={activeEvent}
        Home={true}
      />
      <WelcomeBonus />
      <CricketEvents />
      <Appfooter />
    </div>
  );
};

export default NewHomePage;
