import axios from "axios";

const Url = process.env.REACT_APP_BASEURL;

export const createBet = async (data) => {

    const res = await axios({
        url: Url + "/placeBet",
        method: "POST",
        headers: {
            Authorization: localStorage.getItem("sfwa_tkn"),
        },
        data
    })
    console.log("res" , res)
    return res.data

}