import React, { useEffect, useState } from "react";
import Appfooter from "../components/footer/Appfooter";
import AppNavbar from "../components/home/AppNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import mi from "../assets/images/png/mumbaiIndians.png";
import dc from "../assets/images/png/delhiCapitals.png";
import BetSlip from "../components/bottomnav/BetSlip";
import { GetCricketEvents } from "../redux/Cricket/CircketSlice";
import { GetDateDescription, TimePending } from "../Services/Common";
import { setEvent } from "../redux/SingleEvents/SelectedEvent";
import BottomNav from "../components/bottomnav/BottomNav";

function SelectedBannerPage() {
  const { selected } = useParams();
  const Cricket = useSelector((state) => state.Cricket.events);
  const [isBetting, setIsBetting] = useState(false);
  const dispatch = useDispatch();
  const [filteredEvents, setFilteredEvents] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (Cricket.length === 0) {
      dispatch(GetCricketEvents());
    }
  }, [dispatch, Cricket.length]);

  useEffect(() => {
    console.log(Cricket)
    if (Cricket.length > 0 && selected) {
      const filtered = Cricket.filter(
        (event) => event.series.id === selected
      );
      setFilteredEvents(filtered);
    }
  }, [Cricket, selected]);

  return (
    <div>
      <div className="container mt-4">
        <p className="mb-0 ff_inter fs_19 text-white fw-medium">{filteredEvents[0]?.series?.name}</p>
        {filteredEvents.length > 0 ? (
          <div className="">
            {filteredEvents
              ?.filter(
                (value) => TimePending(value?.event?.startDate) !== "ENDED"
              )
              ?.map((value, index) => (
                <div
                  key={index}
                  className="matches-div rounded-2 px-3 py-4 mt-3"
                >
                  <div
                    onClick={() => {
                      dispatch(setEvent(value));
                      navigate("/Betting");
                    }}
                  >
                    <p className="text-white mb-0 ff_inter fw-medium fs_12">
                      {value?.series?.name}
                    </p>
                    <div className="events-hr-line mt-3"></div>
                    <div className="d-flex align-items-center justify-content-between mt-4 ">
                      <div className="text-center">
                        <img
                          style={{ width: "48px" }}
                          src={mi}
                          alt="mumbaiIndians"
                        />
                        <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                          {value?.runners[0]?.name}
                        </p>
                      </div>
                      <div className="text-center">
                        <p className="mb-0 text-white ff_inter fs_11 fw-medium">
                          {GetDateDescription(value?.event?.startDate)}
                        </p>
                        <p className="mb-0 clr_green ff_inter fs_19 fw-bold">
                          {/* 234:53 */}
                          {TimePending(value?.event?.startDate)}
                        </p>
                      </div>
                      <div className="text-center">
                        <img
                          style={{ width: "48px" }}
                          src={dc}
                          alt="delhiCapitals"
                        />
                        <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                          {value?.runners[1]?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between gap-3 mt-3">
                    <div
                      onClick={() => setIsBetting(true)}
                      className="bg_clr_dark_blue_2 betting-info-div rounded-2 w-100 d-flex flex-column align-items-center justify-content-center"
                    >
                      <p className="text-white ff_inter fw-bold fs_15 mb-0">
                        1.01
                      </p>
                      <p className="clr_grey ff_inter fw-bold fs_12 mb-0">1</p>
                    </div>
                    <div
                      onClick={() => setIsBetting(true)}
                      className="bg_clr_dark_blue_2 betting-info-div rounded-2 w-100 d-flex flex-column align-items-center justify-content-center"
                    >
                      <p className="text-white ff_inter fw-bold fs_15 mb-0">
                        1.01
                      </p>
                      <p className="clr_grey ff_inter fw-bold fs_12 mb-0">1</p>
                    </div>
                  </div>
                </div>
              ))}
            <div
              onClick={() => setIsBetting(false)}
              className={`body-overlay ${
                isBetting === true ? "d-block" : "d-none"
              }`}
            ></div>
            <BetSlip
              active={isBetting}
              setIsBettingFalse={() => setIsBetting(false)}
            />
          </div>
        ) : (
          <p className="text-white ff_inter fw-bold fs_15 mb-0 text-center my-4 py-4">No events found for this series.</p>
        )}
      </div>
      <Appfooter />
      <BottomNav />
    </div>
  );
}

export default SelectedBannerPage;
