import AppNavbar from "../components/home/AppNavbar";
import speaker from "../assets/images/svg/speaker.svg";
import EventsToBet from "../components/betting/EventsToBet";
import Appfooter from "../components/footer/Appfooter";
import CommenButton from "../components/CommenButton";
import { useEffect, useState } from "react";
import mi from "../assets/images/png/mumbaiIndians.png";
import dc from "../assets/images/png/delhiCapitals.png";
import { useNavigate, useParams } from "react-router-dom";
import BetSlip from "../components/bottomnav/BetSlip";
import { GetDateDescription, getTodayDate, getTomorrowDate, TimePending } from "../Services/Common";
import { setEvent } from "../redux/SingleEvents/SelectedEvent";
import { GetSportById } from "../Services/SportsRadar";
import { useDispatch } from "react-redux";
import Skeletion from "../components/skeletion/Skeletion";

const UpomingEvents = () => {
    useEffect(() => {
        window.scroll("top", 0);
    }, []);
    const [Matches, setMatches] = useState([]);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [currentGame, setCurrentGame] = useState("")
    const navigate = useNavigate();
    const [isBetting, setIsBetting] = useState(false);

    if (!isBetting) {
        document.body.style.overflow = "visible";
    } else {
        document.body.style.overflow = "hidden";
    }

    async function OtherMatches() {
        const data = {
            "sport_id": id,
            "date": await getTomorrowDate()
        }
        await GetSportById(data).then(res => {
            console.log("mathes", res)
            setCurrentGame(res?.sport?.name)
            setMatches(res?.sport_events)
        })
    }

    const TimePending2 = (d) => {
        const now = new Date();
        const target = new Date(d);
        const diff = target - now;

        if (diff < 0) {
            return "Live";
        }

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

        return `${days} days ${hours} hours ${minutes} minutes`;
    }
    useEffect(() => {
        OtherMatches();
    }, []);
    return (
        <div>
            <div className="container px-3 mt-3">
                <div className="bg_clr_dark_grey_3 p-2 rounded-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <img style={{ width: "30px" }} src={speaker} alt="speaker" />
                            <p className="mb-0 text-white ff_heebo fs_11">
                                IPL Cup Winner Market Available On Our Exchange...
                            </p>
                        </div>
                    </div>
                </div>
                <CommenButton />
            </div>
            <div className="container">
                {Matches.length > 0 ? Matches?.filter(
                    (value) => TimePending(value?.event?.startDate) !== "ENDED"
                )?.map((value, index) => (
                    <div
                        key={index}
                        className="matches-div rounded-2 px-3 py-4 h-100 mt-3"
                        onClick={() => {
                            dispatch(setEvent(value));
                            navigate("/sport_details/" + value.id + "/" + currentGame  + "/" + value?.$.next_live_time);
                        }}
                    >
                        <div>
                            <p className="text-white mb-0 ff_inter fw-medium fs_12">
                                {value?.tournament?.name}
                            </p>
                            <div className="events-hr-line mt-3"></div>
                            <div className="d-flex align-items-center justify-content-between mt-4 ">
                                <div className="text-center">
                                    <img
                                        style={{ width: "48px" }}
                                        src={mi}
                                        alt="mumbaiIndians"
                                    />
                                    <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                                        {value?.competitors[0]?.name}
                                    </p>
                                </div>
                                <div className="text-center">
                                    <p className="mb-0 text-white ff_inter fs_11 fw-medium">
                                        {GetDateDescription(value?.scheduled)}
                                    </p>
                                    <p className="mb-0 clr_green ff_inter fs_11 fw-bold">
                                        {value.status == "closed" ? "Ended" : TimePending2(value?.scheduled)}
                                    </p>
                                </div>
                                <div className="text-center">
                                    <img
                                        style={{ width: "30px" }}
                                        src={dc}
                                        alt="delhiCapitals"
                                    />
                                    <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                                        {value?.competitors[1]?.name}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 gap-1">
                            {value?.markets[0]?.books[0]?.outcomes?.map((item, index) => {
                                return (
                                    <div className="w-50">
                                        <div
                                            className="bg_clr_dark_blue_2 betting-info-div rounded-2 w-100 d-flex flex-column align-items-center justify-content-center"
                                        >
                                            <p className="text-white ff_inter fw-bold fs_15 mb-0">
                                                {item.odds}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                )) :
                    <div className="mt-3 d-flex flex-column justify-content-center gap-2 align-items-center">
                        <Skeletion height={"210px"} width={"350px"} borderRadius={5} />
                        <Skeletion height={"210px"} width={"350px"} borderRadius={5} />
                        <Skeletion height={"210px"} width={"350px"} borderRadius={5} />
                        <Skeletion height={"210px"} width={"350px"} borderRadius={5} />
                    </div>
                }


                <div
                    onClick={() => setIsBetting(false)}
                    className={`body-overlay ${isBetting === true ? "d-block" : "d-none"
                        }`}
                ></div>
                <BetSlip
                    active={isBetting}
                    setIsBettingFalse={() => setIsBetting(false)}
                />
            </div>
            <Appfooter />
        </div>
    );
};

export default UpomingEvents;
