import React, { useEffect, useState } from "react";
import AppNavbar from "../components/home/AppNavbar";
import "../components/home/Home.css";
import HomeHero from "../components/home/HomeHero";
import Categories from "../components/home/Categories";
import Events from "../components/home/Events";
import Appfooter from "../components/footer/Appfooter";
import LetsChat from "../components/home/LetsChat";
import BottomNav from "../components/bottomnav/BottomNav";
import CasinoCategory from "../components/casinopage/CasinoCategory";
import CasinoEvents from "../components/casinopage/CasinoEvents";
import trophy from "../assets/images/svg/trophy.svg";
import casinoCoins from "../assets/images/svg/casinoCoins.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { GetCricketEvents } from "../redux/Cricket/CircketSlice";
import { GetSoccerEvents } from "../redux/Soccer/SoccerSlice";
import { useNavigate } from "react-router-dom";
import { GetAllCasnio } from "../redux/Casino/CasinoSlice";

const HomePage = () => {
  const [selectCategory, setSelectCategory] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userIP, setUserIP] = useState("");
  const [userLocation, setUserLocation] = useState(null);

  const getUserIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org/?format=json");
      console.log("IP DAta" , response)
      setUserIP(response.data.ip);
      getUserLocation(response.data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  const getUserLocation = async (ip) => {
    try {
      const response = await axios.get(`https://ipapi.co/${ip}/json/`);
      setUserLocation(response.data);
      console.log("country" , response)
    } catch (error) {
      console.error("Error fetching user location:", error);
    }
  };

  useEffect(() => {
    dispatch(GetCricketEvents());
    dispatch(GetSoccerEvents());
  }, []);
  return (
    <div className="">
      <HomeHero />
      <Categories />
      <Events />
      <Appfooter />
    </div>
  );
};

export default HomePage;
