import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cricket from '../../assets/images/svg/cricketStumps.svg';
import { useSelector } from 'react-redux';
import EventsInfo from '../betting/EventsInfo.';
import socketService from '../../Services/SocketService';

function OtherSportsEventDetails() {
    const { sport, id } = useParams();
    const SelectedEvent = useSelector((state) => state.SelectedEvent.events);
    const [match, setMatch] = useState();
    const [rippleIndexes, setRippleIndexes] = useState({}); // Track ripple state for each outcome

    useEffect(() => {
        if (id) {
            socketService.connect();

            socketService.emit("join", { userId: id }, (error, message) => {
                if (error) {
                    console.log(`Error joining: ${error}`);
                } else {
                    console.log(message);
                }
            });

            const handleBookmark = (data) => {
                console.log(data);
                setMatch(data);
                triggerRippleEffect(data); // Trigger ripple effect when match data changes
            };

            socketService.on("sportsradar", handleBookmark);

            return () => {
                socketService.disconnect();
            };
        }
    }, [id, socketService]);

    const triggerRippleEffect = (newMatch) => {
        if (newMatch?.markets) {
            const newRippleIndexes = {};

            newMatch.markets.forEach((market, marketIndex) => {
                market.outcomes.forEach((outcome, outcomeIndex) => {
                    newRippleIndexes[`${marketIndex}-${outcomeIndex}`] = true;
                });
            });

            setRippleIndexes(newRippleIndexes);

            // Remove ripple effect after animation duration
            setTimeout(() => {
                setRippleIndexes({});
            }, 600); // 600ms matches the CSS animation duration
        }
    };

    return (
        <div className='container p-3'>
            <div className="">
                <div className="d-flex align-items-center gap-2">
                    <img src={cricket} alt="stumps" />
                    <p className="mb-0 ff_inter fs_19 text-white fw-medium">
                        {sport}
                    </p>
                </div>
            </div>
            <EventsInfo event={SelectedEvent} />
            <div className='gap-3 overflow-auto matches-box-div'>
                {match && match?.markets?.map((item, marketIndex) => {
                    return (
                        <div className='matches-div rounded-2 px-3 py-4 h-100 mt-3' key={marketIndex}>
                            <p className='text-white'>
                                {item.name}
                            </p>
                            <div>
                                {item?.outcomes?.map((odds, outcomeIndex) => {
                                    const rippleClass = rippleIndexes[`${marketIndex}-${outcomeIndex}`] ? 'ripple' : '';

                                    return (
                                        <div
                                            className={`bg_clr_dark_blue_2 betting-info-div my-2 rounded-2 w-100 d-flex flex-column align-items-center justify-content-center text-white ${rippleClass}`}
                                            key={outcomeIndex}
                                        >
                                            <div className='d-flex text-center flex-column'>
                                                <p className='text-red mb-0'>
                                                    {odds.name}
                                                </p>
                                                <div className=''>
                                                    <p className='mb-0'>{odds.odds}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default OtherSportsEventDetails;
