import React, { useEffect, useState } from "react";
import cricket from "../../assets/images/svg/cricketStumps.svg";
import arrowRight from "../../assets/images/svg/arrowRight.svg";
import mi from "../../assets/images/png/mumbaiIndians.png";
import dc from "../../assets/images/png/delhiCapitals.png";
import BetSlip from "../bottomnav/BetSlip";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetDateDescription, getTodayDate, TimePending } from "../../Services/Common";
import { setEvent } from "../../redux/SingleEvents/SelectedEvent";
import Skeletion from "../skeletion/Skeletion";
import { GetSportById } from "../../Services/SportsRadar";

const PopularEvents = () => {
  const [Matches, setMatches] = useState([]);
  const [currentGame, setCurrentGame] = useState("")
  const [isBetting, setIsBetting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isBetting) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isBetting]);

  async function OtherMatches() {
    const data = {
      "sport_id": "sr:sport:21",
      "date": await getTodayDate()
    }
    await GetSportById(data).then(res => {
      console.log("mathes", res)
      // setCurrentGame(res?.sport?.name)
      setMatches(res)
    })
  }

  const TimePending2 = (d) => {
    const now = new Date();
    const target = new Date(d);
    const diff = target - now;

    if (diff < 0) {
      return "Live";
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${days} days ${hours} hours ${minutes} minutes`;
  }
  useEffect(() => {
    OtherMatches();
  }, []);


  return (
    <div className="ps-3">
      <div className="container ps-0">
        <div className="d-flex align-items-center justify-content-between mt-4">
          <div className="d-flex align-items-center gap-2">
            <img src={cricket} alt="stumps" />
            <p className="mb-0 ff_inter fs_19 text-white fw-medium">Popular Events</p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <p className="text-white mb-0 fs_12 fw-medium ff_inter" onClick={() => {
              navigate("/Upcoming/sr:sport:21")
            }}>
              Upcoming Events
            </p>
            <img src={arrowRight} alt="rightArrow" />
          </div>
        </div>
        {Matches?.length > 0 ? (
          <div className="d-flex gap-3 overflow-auto matches-box-div">
            {Matches?.length > 0 ? Matches?.filter(
              (value) => TimePending(value?.event?.startDate) !== "ENDED"
            )?.map((value, index) => (
              <div
                key={index}
                className="matches-div rounded-2 px-3 py-4 h-100 mt-3"
                onClick={() => {
                  dispatch(setEvent(value));
                  navigate("/sport_details/" + value.id + "/" + currentGame + "/" + value?.$.next_live_time);
                }}
              >
                <div>
                  <p className="text-white mb-0 ff_inter fw-medium fs_12">
                    {value?.tournament?.$.name}
                  </p>
                  <div className="events-hr-line mt-3"></div>
                  <div className="d-flex align-items-center justify-content-between mt-4 ">
                    <div className="text-center">
                      <img
                        style={{ width: "48px" }}
                        src={mi}
                        alt="mumbaiIndians"
                      />
                      <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                        {value?.competitors.competitor[0]?.$.name}
                      </p>
                    </div>
                    <div className="text-center">
                      <p className="mb-0 text-white ff_inter fs_11 fw-medium">
                        {GetDateDescription(value?.$.scheduled)}
                      </p>
                      <p className="mb-0 clr_green ff_inter fs_11 fw-bold">
                        {value.status == "closed" ? "Ended" : TimePending2(value?.$.scheduled)}
                      </p>
                    </div>
                    <div className="text-center">
                      <img
                        style={{ width: "30px" }}
                        src={dc}
                        alt="delhiCapitals"
                      />
                      <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                        {value?.competitors.competitor[1]?.$.name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-3 gap-1">
                  {/* {value?.markets[0]?.books[0]?.outcomes?.map((item, index) => {
                   return (
                     <div className="w-50">
                       <div
                         className="bg_clr_dark_blue_2 betting-info-div rounded-2 w-100 d-flex flex-column align-items-center justify-content-center"
                       >
                         <p className="text-white ff_inter fw-bold fs_15 mb-0">
                           {item.odds}
                         </p>
                       </div>
                     </div>
                   )
                 })
                 } */}
                </div>
              </div>
            )) :
              <div className="mt-3 d-flex flex-column justify-content-center gap-2 align-items-center overflow-hidden">
                <Skeletion height={"210px"} width={"350px"} borderRadius={5} />
              </div>
            }


            <div
              onClick={() => setIsBetting(false)}
              className={`body-overlay ${isBetting === true ? "d-block" : "d-none"
                }`}
            ></div>
            <BetSlip
              active={isBetting}
              setIsBettingFalse={() => setIsBetting(false)}
            />
          </div>
        ) : (
          <div className="mt-3 d-flex gap-2">
            <Skeletion height={180} width={290} borderRadius={5} />
          </div>
        )}

        <div
          onClick={() => setIsBetting(false)}
          className={`body-overlay ${isBetting === true ? "d-block" : "d-none"
            }`}
        ></div>
        <BetSlip
          active={isBetting}
          setIsBettingFalse={() => setIsBetting(false)}
        />
      </div>
    </div>
  );
};

export default PopularEvents;
